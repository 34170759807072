export const environment = {
  production: true,
  idsApiUrl: 'https://mpidentityserverwa001-qa.azurewebsites.net/',
  lsApiUrl: 'https://sales.lightsailed.com/',
  mpApiUrl: 'https://api-qa.mindplay.com/',
  mpCoachUrlStudent: 'https://read-qa.mindplay.com/mvrc/default.aspx',
  mpCoachUrlNonStudent: '',
  mpAccountUrl: 'https://account-qa.mindplay.com/login',
  mpManagerUrl: 'https://manager-qa.mindplay.com/account/login',
  lsV10Url: 'https://lightsailed.com/school/literacy',
  cleverClientId: '665a46d36204a8d83c43',
  classLinkClientId: 'c1536154019710ee499dfbda6d1a84c98ec4fe27e4f0f2',
  gg4lClientId: 'PTRAPOWSUM',
  googleClientId: '688502325146-q9kskhd9mn136r4dbcjasoth7q5h1eqk.apps.googleusercontent.com',
  microsoftSSOClientId: 'b32c61df-1250-41c4-a86e-8315b8381933',
  version: '20241115.9',
  applicationInsightsConnectionString: 'InstrumentationKey=b40cd09f-c712-4153-96e8-95842172d36d;IngestionEndpoint=https://eastus-8.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus.livediagnostics.monitor.azure.com/;ApplicationId=b068afe6-c708-445b-a84d-2c5be782f798',
  environment: 'qa'
};
